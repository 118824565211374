import { z } from "zod";

import { discountCodeSchema } from "./promocodes/discountCode.contract";
import { revealCodeSchema } from "./promocodes/revealCode.contract";
import { ticketCartTicketValidator } from "./ticketCartTicketValidator";

export const BasketSchemaVersion = 13958726; // (new Date().getTime() / 123456).toFixed(0)

export const basketTicketsValidator = z.object({
  __v: z.literal(BasketSchemaVersion),
  basket: z.object({
    idempotencyKey: z.string().nullable(),
    tickets: z.array(ticketCartTicketValidator),
    revealCodes: z.array(revealCodeSchema).default([]),
    discountCodes: z.array(discountCodeSchema).max(1).default([]),
  }),
});
export type BasketTickets = z.infer<typeof basketTicketsValidator>;

export const EmptyBasket: BasketTickets = {
  __v: BasketSchemaVersion,
  basket: {
    idempotencyKey: null,
    tickets: [],
    revealCodes: [],
    discountCodes: [],
  },
};
